<template>
  <v-container fluid>
    <div>
      <v-row>
        <v-col>
          <v-card outlined>
            <v-card-title class="d-flex flex-row">Theme<v-btn class="ml-2" small color="success" :loading="loaders.general" @click="saveTheme">Save</v-btn></v-card-title>
            <v-card-text>
              <div class="d-flex flex-row">
                <v-text-field class="mr-2" v-model="data.theme.primaryColor" label="Primary Color" outlined dense/>
                <v-text-field class="mr-2" v-model="data.theme.secondaryColor" label="Secondary Color" outlined dense/>
                <v-text-field class="mr-2" v-model="data.theme.tertiaryColor" label="Tertiary Color" outlined dense/>
                <v-text-field class="mr-2" v-model="data.theme.highlightColor" label="Highlight Color" outlined dense/>
                <v-text-field class="mr-2" v-model="data.theme.backgroundColor" label="Background Color" outlined dense/>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined>
            <v-card-title class="d-flex flex-row">Home Page Template<v-btn class="ml-2" small color="success" :loading="loaders.homepageTemplate" @click="saveHomePageTemplate">Save</v-btn></v-card-title>
            <v-card-text>
              <div class="d-flex flex-row">
                <v-textarea
                  label="Template"
                  v-model="data.homepageTemplate"
                  outlined
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from "axios";
import utils from '../../plugins/helpers'

export default {
  name: 'Home',
  data () {
    return {
      utils: utils,
      loaders: {
        page: false,
        general: false,
        homepageTemplate: false
      },
      snackObj: {
        text: "",
        state: false,
        color: "success"
      },
      data: {
        theme: {
          primaryColor: "",
          secondaryColor: "",
          tertiaryColor: "",
          accentColor: "",
          backgroundColor: "",
        },
      }
    }
  },
  computed: {
    ...mapGetters(['getEndpoint', 'isAllowed', 'getGlobalValue'])
  },
  async mounted(){
    try { 
      this.loaders.page = true;

      await this.fetchData();

    } catch (error) {
      console.error(error)
      this.snack(error.msg || error.msg?.message || error, "error");
    }finally {
      this.loaders.page = false;
    }
  },
  methods: {
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    async saveTheme(){
      try {
        let res = await axios.post(`${this.getEndpoint}/api/globals/VEC_EC_CONFIG`, {data: this.data.theme})
        if(res.data.error) throw res.data.error
        console.log(res.data)
      } catch (error) {
        this.snack(error)
        console.log(error)
      }

    },
    async saveHomePageTemplate(){
      try {
        this.loaders.homepageTemplate = true
        let res = await axios.put(`${this.getEndpoint}/api/globals/homepageTemplate`, {data: this.data.homepageTemplate})
        if(res.data.error) throw res.data.error
        console.log(res.data)
        await this.fetchData()
      } catch (error) {
        this.snack(error)
        console.log(error)
      } finally {
        this.loaders.homepageTemplate = false
      }

    },
    async fetchData(){
      try {
        this.loaders.page = true;
        let res = await axios.get(`${this.getEndpoint}/api/globals/VEC_EC_CONFIG`)
        if(res.data.error) throw res.data.error
        this.data = res.data.data.value
        console.log(this.data)
      } catch (error) {
        console.log(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      } finally {
        this.loaders.page = false;
      }
    }
  }
}
</script>
